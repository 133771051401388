$(function () {

	$(document).on("click", ".header__navbar", function () {
		$(this).toggleClass("is-active");
		$(".nav").toggleClass("is-active");
	})
})


/* ページ内リンク（別ページ） */
$(window).on('load', function () {
	id = location.hash;
	speed = 0;
	headerHight = $(".header").outerHeight() - 80;
	if ('' != id) {
		pos = $(id).offset().top - headerHight;
		$('html, body').animate({ scrollTop: pos }, speed);
	}
});
